<template>
  <section class="about-results">
    <div class="about__inner">
      <div class="header__results-item">
        <span
          class="results-number num"
          data-val="125"
        >125</span>
        <span class="results-text">Заявок<br>в день</span>
      </div>
      <div class="header__results-item results-item--special">
        <span
          class="results-number num"
          data-val="523"
        >523</span>
        <span class="results-text">Довольных<br>клиента</span>
      </div>
      <div class="header__results-item">
        <span
          class="results-number num"
          data-val="361"
        >361</span>
        <span class="results-text">Связей с<br> менеджером</span>
      </div>
      <div class="header__results-item results-item--special">
        <span
          class="results-number num"
          data-val="873"
        >873</span>
        <span class="results-text">Выездов<br> специалистов</span>
      </div>
    </div>    
  </section>
</template>

<script>
export default {
  name: 'AboutResults',
  mounted() {
    let valueDisplays = document.querySelectorAll('.num');
    let interval = 1000;
    valueDisplays.forEach(element => {
        let startValue = 0;
        let endValue = parseInt(element.getAttribute("data-val"));
        let duration = Math.floor(interval / endValue);
        let counter = setInterval(function(){
            startValue += 1;
            element.textContent = startValue;
            if (startValue == endValue){
                clearInterval(counter);
            }
        }, duration);
    });
  }
}
</script>
